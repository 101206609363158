import React from 'react';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { currentUser } from '../../constants/defaultValues';
import IntlMessages from '../../helpers/IntlMessages';
import { setCurrentUser } from '../../helpers/Utils';
import AuthService from '../../services/AuthService';
import { LOGIN_USER, LOGOUT_USER } from '../actions';
import { loginUserError, loginUserSuccess } from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

function* loginWithEmailPassword({ payload }) {
  const { history } = payload;

  try {
    const loginUser = yield call(AuthService.login, payload.user);

    if (loginUser?.token) {
      if (loginUser === 'INVALID_USER') {
        yield put(loginUserError(<IntlMessages id='apiErrors.invalidUser' />));

        return;
      }

      const item = { uid: loginUser, ...currentUser };

      setCurrentUser(item);

      yield put(loginUserSuccess(item));

      if (item) history.push('/app/reports/analytics');
    } else {
      yield put(loginUserError(<IntlMessages id='apiErrors.unableToLog' />));
    }
  } catch (error) {
    if (error === 'NO_INTERNET') {
      yield put(loginUserError(<IntlMessages id='networkError.noInternet' />));

      return;
    }

    if (error?.response?.data[0] === 'INVALID_USER') {
      yield put(loginUserError(<IntlMessages id='apiErrors.invalidUser' />));

      return;
    }

    yield put(loginUserError(<IntlMessages id='apiErrors.serverError' />));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async history => {
  history.push('/');
};

function* logout({ payload }) {
  const { history } = payload;

  setCurrentUser({});

  yield call(logoutAsync, history);
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}

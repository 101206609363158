import axios from 'axios';

import { API_URL } from './config';

let conected = window.navigator.onLine;

window.addEventListener('online', function () {
  conected = true;
});

window.addEventListener('offline', function () {
  conected = false;
});

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!conected) return Promise.reject('NO_INTERNET');

    return Promise.reject(error);
  }
);

api.authURL = `${API_URL}/api-token-auth/web/`;
api.authRefreshURL = `${API_URL}/api-token-refresh/`;
api.url = API_URL;

const STAGE = {
  homologation: {
    url: 'https://api.rolmann.hom.leaderaplicativos.com.br/api/v1',
  },
  production: {
    url: 'https://api.rolmann.leaderaplicativos.com.br/api/v1',
  },
};

export const API_URL = STAGE.homologation.url;

export const BROWSER_LANGUAGE = window.navigator.language;
